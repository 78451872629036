/*
 * Post menu
 */

.post-menu {
  padding-left: 20px;
  min-width: 200px;
  max-width: 230px;

  .post-menu-title {
    font-size: $base-font-size * 1.5;
    margin-bottom: 14px;
    font-weight: 600;
  }

  .post-menu-content {
    ul {
      border-left: 1px solid #e9ecef;
      $indent: divide($base-font-size, 4);
      $active-bgcolor: #ecebec;

      @for $i from 2 to 7 {
        .h-h#{$i} {
          padding-inline-start: $indent + ($i - 2) * $base-font-size * 1.3;
          font-size: $base-font-size * 1.1;
          line-height: 1.4;
        }
      }

      a {
        display: flex;
        padding: 2px 8px;
        color: darken($text-color, 10%);

        * {
          pointer-events: none;
        }

        &:hover {
          text-decoration: none;
          color: lighten($text-color, 30%)!important;
        }
      }

      .active {
        background-color: $active-bgcolor;
        transition: background 0.5s;
        border-left: 2px solid #202020;
        margin-left: -2px;

        &:hover {
          background-color: lighten($active-bgcolor, 2%);
        }

        a {
          color: #121416;
        }
      }
    }
  }
}
